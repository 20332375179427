import styled from 'styled-components'

//images

import BgBanner from 'images/banners/banner-clube.jpg'
import LineList from 'images/line-list-funcional.svg'

export const Banner = styled.section`
  position: relative;
  width: 100%;
  height: 580px;
  margin-top: 80px;
  background: url(${BgBanner}) no-repeat center center;
  padding-top: 99px;
  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #06cfb6;
    width: 600px;
    height: 8px;
  }
  @media (max-width: 1050px) {
    padding: 40px 0px;
    height: auto;
    background-size: cover;
    .container {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: 768px) {
    margin-top: 80px;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
      margin: 0 auto;
    }
  }
  @media (max-width: 480px) {
    margin-top: 60px;
  }
`

export const TextoBanner = styled.div`
  max-width: 485px;
  margin-top: 40px;
  h2 {
    font: normal bold 48px/55px 'DepotNew';
    color: #ffffff;
    margin-bottom: 25px;
  }
  p {
    line-height: 26px;
    color: #ffffff;
    opacity: 0.9;
    max-width: 469px;
  }
  @media (max-width: 1050px) {
    max-width: 485px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  @media (max-width: 480px) {
    h2 {
      text-align: center;
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 10px;
    }
    p {
      text-align: center;
      font-size: 14px;
      max-width: 280px;
      margin: 0 auto;
    }
  }
`

export const BoxForm = styled.div`
  width: 623px;
  background: #ffffff;
  box-shadow: 0px 13px 30px rgba(121, 155, 179, 0.22);
  border-radius: 8px 0px;
  padding: 55px 77px 86px 74px;
  .top {
    align-items: flex-start;
  }
  .esq {
    h3 {
      font: normal bold 24px/29px 'DepotNew';
      color: #3c3d40;
      margin-bottom: 6px;
    }
    span {
      font: normal normal 18px/22px 'DepotNew';
      color: #636466;
    }
  }
  .dir {
    text-align: right;
    span {
      display: block;
      font-family: 'DepotNew';
      line-height: 22px;
      text-align: right;
      color: #b1b2b3;
    }
    a {
      font-family: 'DepotNew';
      font-weight: 500;
      line-height: 22px;
      color: #0095da;
    }
  }
  .btn-forgot-password {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    line-height: 20px;
    color: #979ba1;
  }
  .MuiFormControlLabel-label {
    color: #636466;
    font: normal normal 14px/18px 'DepotNew';
  }
  .label-check {
    font-size: 14px;
    line-height: 18px;
    color: #636466;
    display: flex;
    align-items: center;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 30px 20px;
    .btn-forgot-password {
      font-size: 13px;
    }
  }
`

export const Ofertas = styled.section`
  padding-top: 146px;
  padding-bottom: 99px;
  h2 {
    font: normal bold 36px/40px 'DepotNew';
    color: #4f5052;
    margin-bottom: 40px;
  }
  .container {
    overflow: hidden;
  }
  @media (max-width: 1050px) {
    padding: 40px 0px;
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 480px) {
    h2 {
      font-size: 28px;
      line-height: 1;
    }
  }
`

export const SectionFuncional = styled.section`
  background-color: #f7f7f9;
  padding-top: 90px;
  padding-bottom: 100px;
  h2 {
    text-align: center;
    font: normal bold 36px/40px 'DepotNew';
    color: #4f5052;
    margin-bottom: 27px;
  }
  .subtitle {
    display: block;
    text-align: center;
    font-size: 20px;
    line-height: 26px;
    color: #7b7c80;
    margin-bottom: 56px;
  }
  ul {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1085px;
    margin: 0 auto;
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        width: 230px;
        height: 5px;
        background: url(${LineList}) no-repeat center center;
        position: absolute;
        z-index: 1;
        right: -172px;
        top: 80px;
      }
      .icone {
        position: relative;
        background-color: #cce8f8;
        width: 171px;
        height: 171px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 26px;
        .num {
          position: absolute;
          top: 0;
          left: 0;
          width: 43px;
          height: 43px;
          background-color: #06cfb6;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: DepotNew-Bold;
          font: normal bold 24px/29px 'DepotNew';
          text-align: center;
          color: #ffffff;
        }
      }
      p {
        max-width: 288px;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #7b7c80;
      }
    }
  }
  @media (max-width: 1050px) {
    padding: 40px 0px;
    h2 {
      margin-bottom: 15px;
    }
    ul {
      width: 100%;
      flex-direction: column;
      align-items: center;
      li {
        margin-bottom: 70px;
        p {
          max-width: 50%;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  @media (max-width: 480px) {
    h2 {
      font-size: 28px;
      line-height: 1;
    }
    .subtitle {
      font-size: 18px;
      line-height: 30px;
    }
    ul {
      li {
        &:first-child {
          .icone {
            img {
              max-width: 35%;
            }
          }
        }
        p {
          max-width: 100%;
          font-size: 14px;
          line-height: 22px;
        }
        .icone {
          width: 130px;
          height: 130px;
          .num {
            width: 30px;
            height: 30px;
            font-size: 15px;
          }
          img {
            max-width: 83%;
          }
        }
      }
    }
  }
`

export const SectionPerguntas = styled.section`
  padding-top: 115px;
  padding-bottom: 37px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 42px;
    .icone {
      width: 56px;
      height: 56px;
      background-color: #e6f3fb;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;
    }
    h2 {
      font: normal bold 36px/40px 'DepotNew';
      text-align: center;
      color: #4f5052;
    }
  }
  .area-perguntas {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .ilustracao {
      margin-left: -190px;
    }
    .todas-perguntas {
      width: 670px;
      margin-top: 80px;
      svg {
        path {
          color: #0095da;
        }
      }
    }
  }
  @media (max-width: 1050px) {
    padding: 40px 0px;
    .area-perguntas {
      flex-direction: column;
      align-items: center;
      .ilustracao {
        margin: 0 auto;
        margin-top: -60px;
      }
    }
  }
  @media (max-width: 480px) {
    .title {
      h2 {
        font-size: 28px;
      }
    }
    .area-perguntas {
      .ilustracao {
        width: 70%;
        margin-top: -20px;
      }
      .todas-perguntas {
        width: 100%;
        margin-top: 0;
        .MuiExpansionPanelDetails-root {
          padding: 15px;
        }
        .makeStyles-heading-75 {
          padding: 0px 15px;
          height: 60px;
          .makeStyles-title_pergunta-76 {
            font-size: 15px;
            line-height: 1.1;
          }
        }
        .makeStyles-resposta-77 {
          font-size: 14px;
        }
      }
    }
  }
`

export const TitleBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 48px;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    .esq {
      margin-bottom: 20px;
      h3 {
        text-align: center;
      }
    }
    .dir {
      text-align: center;
    }
  }
`
