import React, { Component } from 'react'

import { StyleBoxOferta } from './style'

class BoxOferta extends Component {
  state = {}
  render() {
    return (
      <StyleBoxOferta to="/">
        <div className="img">
          <img src={this.props.img_oferta} alt="Imagem oferta" />
        </div>
        <div className="info">
          <h3>{this.props.nome_oferta}</h3>
          <span>{this.props.nome_empresa}</span>
          <p>{this.props.descricao_oferta}</p>
          <strong>veja mais</strong>
        </div>
      </StyleBoxOferta>
    )
  }
}

BoxOferta.defaultProps = {
  nome_oferta: 'Nome da Oferta',
  nome_empresa: 'Nome da empresa',
  descricao_oferta: 'Descrição da oferta',
}

export default BoxOferta
