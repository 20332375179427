import styled from 'styled-components'

import { Link } from 'gatsby'

export const StyleBoxOferta = styled(Link)`
  width: 100%;
  height: 502px;
  box-shadow: 0px 5px 12px rgba(52, 52, 52, 0.11);
  display: block;
  padding: 11px 10px 23px 10px;
  &:hover {
    .info {
      strong {
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.3s;
      }
    }
  }
  .img {
    border-radius: 8px 0px;
    overflow: hidden;
    width: 347px;
    height: 274px;
    margin: 0 auto;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 694px;
    }
  }
  .info {
    padding: 0px 19px;
    h3 {
      font: normal bold 24px/26px 'DepotNew';
      color: #0095da;
      margin-bottom: 5px;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #7b7c80;
      margin-bottom: 11px;
      display: block;
    }
    p {
      max-width: 289px;
      font-size: 14px;
      line-height: 24px;
      color: #7b7c80;
      margin-bottom: 32px;
      height: 48px;
    }
    strong {
      font: normal bold 15px/24px 'Inter';
      color: #0ac4ac;
      opacity: 0;
      transform: translateY(20px);
      display: block;
      transition: all 0.3s;
    }
  }
  @media (max-width: 480px) {
    .img {
      width: 100%;
      height: auto;
    }
    .info {
      h3 {
        font-size: 22px;
      }
      span {
        font-size: 14px;
      }
    }
  }
`
