import styled from 'styled-components'

export const AreaSlide = styled.div``

export const CtrlSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 57px;
  .swiper-pagination {
    position: relative;
    bottom: 0;
    .swiper-pagination-bullet {
      width: 9px;
      height: 9px;
      background-color: #d8d9d9;
      border-radius: 4.5px;
      opacity: 1;
      margin: 0px 4.5px;
      transition: all 0.3s;
      &.swiper-pagination-bullet-active {
        background-color: #5cb4e7;
        transition: all 0.3s;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    .btn {
      cursor: pointer;
      img {
        display: block;
      }
      &.btn-prev {
        transform: rotate(180deg);
        margin-right: 15px;
      }
    }
  }
`
