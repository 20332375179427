import React, { useState } from 'react'
import Swiper from 'react-id-swiper'
import BoxOferta from '../../boxes/box-oferta'

// Style Component

import { CtrlSlide, AreaSlide } from './style'

// Images Component

import ArroSlide from '../../../images/arrow-slide-ofertas.svg'

import ImgOferta01 from '../../../images/img-oferta-01.jpg'
import ImgOferta02 from '../../../images/img-oferta-02.jpg'
import ImgOferta03 from '../../../images/img-oferta-03.jpg'

const CarrouselOfertas = () => {
  const [swiper, updateSwiper] = useState(null)

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const params = {
    slidesPerView: 3,
    preloadImages: false,
    spaceBetween: 28,
    speed: 600,
    containerClass: 'slide-ofertas',
    pagination: {
      el: '.ctrl-slide-ofertas .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
      1600: {
        slidesPerView: 3,
      },
    },
  }

  return (
    <AreaSlide>
      <Swiper {...params} getSwiper={updateSwiper}>
        <div>
          <BoxOferta
            img_oferta={ImgOferta01}
            nome_oferta="Nome da oferta"
            nome_empresa="Nome da empresa"
            descricao_oferta="Desciçao da oferta. Lorem ipsum dolor sit amet, consectetur adipiscing elit… "
          />
        </div>
        <div>
          <BoxOferta
            img_oferta={ImgOferta02}
            nome_oferta="Nome da oferta"
            nome_empresa="Nome da empresa"
            descricao_oferta="Desciçao da oferta. Lorem ipsum dolor sit amet, consectetur adipiscing elit… "
          />
        </div>
        <div>
          <BoxOferta
            img_oferta={ImgOferta03}
            nome_oferta="Nome da oferta"
            nome_empresa="Nome da empresa"
            descricao_oferta="Desciçao da oferta. Lorem ipsum dolor sit amet, consectetur adipiscing elit… "
          />
        </div>
        <div>
          <BoxOferta
            img_oferta={ImgOferta01}
            nome_oferta="Nome da oferta"
            nome_empresa="Nome da empresa"
            descricao_oferta="Desciçao da oferta. Lorem ipsum dolor sit amet, consectetur adipiscing elit… "
          />
        </div>
        <div>
          <BoxOferta
            img_oferta={ImgOferta02}
            nome_oferta="Nome da oferta"
            nome_empresa="Nome da empresa"
            descricao_oferta="Desciçao da oferta. Lorem ipsum dolor sit amet, consectetur adipiscing elit… "
          />
        </div>
        <div>
          <BoxOferta
            img_oferta={ImgOferta03}
            nome_oferta="Nome da oferta"
            nome_empresa="Nome da empresa"
            descricao_oferta="Desciçao da oferta. Lorem ipsum dolor sit amet, consectetur adipiscing elit… "
          />
        </div>
      </Swiper>
      <CtrlSlide className="ctrl-slide-ofertas">
        <div className="btns">
          <button onClick={goPrev} className="btn btn-prev">
            <img src={ArroSlide} alt="Seta para voltar slide" />
          </button>
          <button onClick={goNext} className="btn btn-next">
            <img src={ArroSlide} alt="Seta para avançar slide" />
          </button>
        </div>
        <div className="swiper-pagination"></div>
      </CtrlSlide>
    </AreaSlide>
  )
}

export default CarrouselOfertas
