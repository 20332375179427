import React, { Component } from 'react'
import { Link } from 'gatsby'

import Accordion from '../components/accordion'

import Checkbox from '../components/forms/checkbox'

// Styles this component
import { Banner, TextoBanner, BoxForm, Ofertas, SectionFuncional, SectionPerguntas, TitleBox } from '../Style/page-clube-vantagens/style'

// Style Global
import { SeloTitle, FormGroup, FromGroupDouble, Input, ButtonGreen } from '../Style/global'

// Components Globals
import Header from '../components/header'
import Demo from '../components/s-demo/main'
import Footer from '../components/footer/main'
import SEO from '../components/seo'

// Sass Global
import '../sass/main.scss'

// Component Slide Ofertas
import SlideOfertas from 'components/slides/slide-ofertas'

// Images
import IconeCelular from 'images/icone-celular.svg'
import IconeComputador from 'images/icone-computador-clube.svg'
import IconeCartaoClube from 'images/icone-cartao-clube.svg'
import IconePerguntas from 'images/icone-perguntas.svg'
import IlustracaoPerguntas from 'images/ilustracao-perguntas.svg'

class Site extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Clube de Vantagens" />
        <Header />

        <Banner>
          <div className="container">
            <TextoBanner>
              <SeloTitle>Clube de vantagens</SeloTitle>
              <h2>Vantagens e benefícios exclusivos para clientes</h2>
              <p>
                Clientes INOVAFARMA tem descontos em distribuidoras, consultorias, escritório contábil, lojas de móveis e diversas outras
                empresas do segmento farmacêutico.
              </p>
            </TextoBanner>
            <BoxForm>
              <form>
                <TitleBox>
                  <div className="esq">
                    <h3>Clube de vantagens</h3>
                    <p>Acesso à consulta dos benefícios</p>
                  </div>
                  <div className="dir">
                    <span>Ainda não é cliente?</span>
                    <Link to="/sobre/representante">Clique aqui</Link>
                  </div>
                </TitleBox>
                <FormGroup>
                  <label>CNPJ</label>
                  <Input type="text" />
                </FormGroup>
                <FormGroup>
                  <label>Senha</label>
                  <Input type="password" />
                  <Link to="/" className="btn-forgot-password">
                    Esqueci minha senha
                  </Link>
                </FormGroup>
                <FromGroupDouble>
                  <Checkbox txt_label="Manter-me conectado" />
                  <ButtonGreen>Acessar</ButtonGreen>
                </FromGroupDouble>
              </form>
            </BoxForm>
          </div>
        </Banner>

        <Ofertas>
          <div className="container">
            <h2>Nossas ofertas</h2>
            <SlideOfertas />
          </div>
        </Ofertas>

        <SectionFuncional>
          <div className="container">
            <h2>Como funciona?</h2>
            <span className="subtitle">Entenda como é fácil utilizar os benefícios do Clube de Vantagens</span>
            <ul>
              <li>
                <div className="icone">
                  <span className="num">1</span>
                  <img src={IconeCelular} alt="Icone de um celular" />
                </div>
                <p>Você que já é cliente INOVAFARMA possui um código de acesso para utilizar os benefícios do Clube de Vantagens</p>
              </li>
              <li>
                <div className="icone">
                  <span className="num">2</span>
                  <img src={IconeComputador} alt="Icone de um computador" />
                </div>
                <p>Realize o acesso no site e escolha o benefício de uma de nossas empresas parceiras para utilizar.</p>
              </li>
              <li>
                <div className="icone">
                  <span className="num">3</span>
                  <img src={IconeCartaoClube} alt="Icone de um cartao" />
                </div>
                <p>Pronto! Será gerado um código de desconto e você poderá utilizá-lo na empresa escolhida.</p>
              </li>
            </ul>
          </div>
        </SectionFuncional>

        <SectionPerguntas>
          <div className="container">
            <div className="title">
              <div className="icone">
                <img src={IconePerguntas} alt="Icone de perguntas" />
              </div>
              <h2>Perguntas frequentes</h2>
            </div>
            <div className="area-perguntas">
              <img src={IlustracaoPerguntas} className="ilustracao" alt="Ilustracao" />
              <div className="todas-perguntas">
                <Accordion
                  title_pergunta="O QUE É O CLUBE DE VANTAGENS?"
                  resposta="O Clube de Vantagens é um programa de benefícios exclusivo para clientes do INOVAFARMA. O cliente terá acesso a ofertas, descontos e vantagens especiais para adquirir produtos e serviços relacionados com sua farmácia."
                />
                <Accordion
                  title_pergunta="COMO PARTICIPAR DO CLUBE?"
                  resposta="Para participar, é necessário que você seja cliente INOVAFARMA e que sua licença de uso esteja ativa."
                />
                <Accordion
                  title_pergunta="COMO FUNCIONA AS OFERTAS?"
                  resposta="Disponibilizamos ofertas em parceria com os principais fornecedores de produto/serviços do varejo farmacêutico, permitindo você solicitar um cupom de desconto para utilizar o benefício na sua farmácia."
                />
                <Accordion
                  title_pergunta="PRECISO PAGAR ALGUMA COISA PARA PARTICIPAR?"
                  resposta="Para utilizar os benefícios, basta que você seja cliente ativo do INOVAFARMA. Você não precisa pagar nenhum valor a mais por isso."
                />
              </div>
            </div>
          </div>
        </SectionPerguntas>

        <Demo />
        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default Site
