import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const GreenCheckbox = withStyles({
  root: {
    color: '#0095DA',
    '&$checked': {
      color: '#0095DA',
    },
  },
  label: {
    color: 'red',
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

export default function CheckboxLabels(props) {
  const [state, setState] = React.useState({
    checkedG: false,
  })

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }

  return (
    <FormGroup row>
      <FormControlLabel
        control={<GreenCheckbox checked={state.checkedG} onChange={handleChange('checkedG')} value="checkedG" />}
        label={props.txt_label}
      />
    </FormGroup>
  )
}
